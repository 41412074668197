import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import DataProtection from './DataProtection';

export default props => {
  const data = {
    dataProtection: props.data.dataProtection.nodes[0],
  };
  return (
    <Layout>
      <DataProtection {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    dataProtection: allContentfulDataProtection(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        textBlocksWithHeadline {
          headline
          textContent {
            json
          }
        }
      }
    }
  }
`;
